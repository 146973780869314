import DishClassification from './dishes/DishClassification.json'
import DishClassificationDetail from './dishes/DIshClassificationDetail.json'
import Dish from './dishes/Dish.json'
import DishDetail from './dishes/DishDetail.json'
import CookingMethod from './dishes/CookingMethod.json'
import CookingMethodDetail from './dishes/CookingMethodDetail.json'
import FlavorOfFood from './dishes/FlavorOfFood.json'
import FlavorOfFoodDetail from './dishes/FlavorOfFoodDetail.json'
import Specification from './dishes/Specification.json'
import SpecificationDetail from './dishes/SpecificationDetail.json'
import SideDish from './dishes/SideDish.json'
import SideDishDetail from './dishes/SideDishDetail.json'
import Combo from './dishes/Combo.json'
import ComboDetail from './dishes/ComboDetail.json'
import DishGroupDetail from './dishes/DishGroupDetail.json'
import AddDish from './dishes/AddDish.json'
import OrderHistory from './order/OrderHistory.json'
import StoreInformation from './setting/StoreInformation.json'
import OrderDetail from './order/OrderDetail.json'
import OrderStatus from './order/OrderStatus.json'
import OrderType from './order/OrderType.json'
import StaffList from './staff/StaffList.json'
import StaffDetail from './staff/StaffDetail.json'
import OpenStatus from './openStatus'
import StaffView from './staff/StaffView.json'
import ChooseRole from './staff/ChooseRole.json'
import OrderSetting from './setting/OrderSetting.json'
import ThirdPartyDeliveryTeam from './setting/ThirdPartyDeliveryTeam.json'
import Blacklist from './blacklist/Blacklist.json'
import MessageType from './message/MessageType.json'
import Permission from './setting/Permission.json'
import Comment from './member/Comment.json'
import Join from './join'
import Home from './home'
import Menu from './menu'
import Auth from './auth'
import BindPaymentPlatform from './bindPaymentPlatform/index.json'

export default {
  man: '男',
  woman: '女',
  unknown: '未知',
  addMultilingual: '添加多语言',
  inStock: '上架',
  allInStock: '全部上架',
  outOfStock: '下架',
  allOutOfStock: '全部下架',
  all: '全部',
  selectAll: '选择全部',
  add: '新增',
  delete: '删除',
  deleteAll: '删除全部',
  deleteSuccess: '删除成功',
  deleteFail: '删除失败',
  edit: '修改',
  see: '查看',
  ok: '确认',
  cancel: '取消',
  confirm: '确认',
  reset: '重置',
  reject: '拒绝',
  agree: '同意',
  next: '下一步',
  notNow: '不是现在',
  save: '保存',
  saveAndContinue: '保存并继续',
  import: '导入',
  export: '导出',
  operation: '操作',
  search: '搜索',
  selected: '选中',
  yes: '是',
  no: '否',
  tips: '提示',
  open: '打开',
  close: '关闭',
  back: '返回',
  noAddPrice: '不加钱',
  addPrice: '加钱',
  serverConcatError: '服务器连接失败',
  noUsed: '不使用',
  min: '分',
  operationSuccess: '操作成功',
  operationError: '操作失败',
  fileError: {
    0: '图片宽度必须为',
    1: ',高度必须为',
    2: '图片大小必须控制在'
  },
  uploadError: '上传失败',
  uploadImgError: '上传图片失败',
  uploadThumbnailError: '上传缩略图失败',
  welcome: '欢迎进入商家管理',
  noPermission: '没有权限',
  loginTimeout: '登录超时,请重新登录',
  editSuccess: '修改成功',
  editFail: '修改失败',
  mile: '英里',
  auth: Auth,
  menu: Menu,
  dishClassification: DishClassification,
  dishClsDetail: DishClassificationDetail,
  dish: Dish,
  dishDetail: DishDetail,
  cookingMethod: CookingMethod,
  cookingMethodDetail: CookingMethodDetail,
  flavorOfFood: FlavorOfFood,
  flavorOfFoodDetail: FlavorOfFoodDetail,
  specification: Specification,
  specificationDetail: SpecificationDetail,
  sideDish: SideDish,
  sideDishDetail: SideDishDetail,
  combo: Combo,
  comboDetail: ComboDetail,
  dishGroupDetail: DishGroupDetail,
  addDish: AddDish,
  orderHistory: OrderHistory,
  storeInformation: StoreInformation,
  orderDetail: OrderDetail,
  orderStatus: OrderStatus,
  orderType: OrderType,
  staffList: StaffList,
  staffDetail: StaffDetail,
  openStatus: OpenStatus,
  staffView: StaffView,
  orderSetting: OrderSetting,
  thirdPartyDeliveryTeam: ThirdPartyDeliveryTeam,
  home: Home,
  blacklist: Blacklist,
  messageType: MessageType,
  permission: Permission,
  comment: Comment,
  join: Join,
  chooseRole: ChooseRole,
  bindPaymentPlatform: BindPaymentPlatform
}
