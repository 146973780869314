export const settingRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }

  return [
    {
      path: '/store-information',
      name: formatName('store-information'),
      meta: { auth: true, t: "menu.setting.child.storeInformation.label", jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/setting/storeInformation')
    },
    {
      path: '/order-setting',
      name: formatName('order-setting'),
      meta: { auth: true, t: "menu.setting.child.orderSetting.label", jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/setting/orderSetting')
    },
    {
      path: '/permission',
      name: formatName('permission'),
      meta: { auth: true, t: "menu.setting.child.permission.label", jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/setting/permission/Permission')
    },
    {
      path: "/permission-detail",
      name: formatName('permission-detail'),
      meta: { auth: true, t: 'menu.setting.child.permission.detail', jurisdiction: [1, 2] },
      component: () => import('../components/pages/settings/permission/PermissionDetail')
    }
    // 权限管理移植平台端
    // {
    //   path: '/jurisdiction',
    //   name: formatName('jurisdiction'),
    //   meta: { auth: true, t: "menu.setting.child.jurisdiction.label", jurisdiction: [1, 2] },
    //   component: () => import('../views/pages/manage/setting/jurisdiction/Jurisdiction')
    // }
  ]
}
