// Initial Setting State
export const initialState = {
  setting: {
    app_name: 'MioEATS',
    theme_scheme: 'light',
    theme_scheme_direction: 'ltr',
    theme_color: 'theme-color-default',
    header_navbar: 'default',
    sidebar_color: 'sidebar-white',
    sidebar_type: [],
    sidebar_menu_style: 'navs-rounded-all',
    language: 'en',
    user: null,
    open_status: 'pause',
    // 普通商家 -- 1 / 配送商家 -- 2
    jurisdiction: null,
    // 是否admin
    is_admin: false,
    // 菜单权限
    menu_auth_list: [],
    // 功能(按钮)权限
    function_auth_list: [],
    // 商家id
    seller_id: '',
    // 绑定支付平台状态
    bind_payment_status: false
  },
};

// Default settingChoices
export const settingChoices = {
  theme_scheme_direction: {
    choices: ["ltr", "rtl"]
  },
  theme_scheme: {
    choices: ["light", "dark", "auto"]
  },
  theme_color: {
    choices: [
      "theme-color-blue",
      "theme-color-gray",
      "theme-color-red",
      "theme-color-yellow",
      "theme-color-pink",
      "theme-color-default",
    ]
  },
  header_navbar: {
    choices: [
      "default",
      "navs-sticky",
      "nav-glass",
      "navs-transparent",
      "boxed",
    ]
  },
  sidebar_color: {
    choices: [
      "sidebar-white",
      "sidebar-dark",
      "sidebar-color",
      "sidebar-transparent",
    ]
  },
  sidebar_type: {
    choices: ["sidebar-hover", "sidebar-mini", "sidebar-boxed"],
    value: [],
  },
  sidebar_menu_style: {
    choices: [
      "navs-rounded",
      "navs-rounded-all",
      "navs-pill",
      "navs-pill-all"
    ],
    value: "left-bordered",
  },
  language: ["en", "zh"],
  open_status: ["normal", "busy", "pause"]
}
