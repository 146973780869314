import { createI18n } from 'vue-i18n'
import en from './lang/en'
import zh from './lang/zh'

export default new createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: {
    en,
    zh
  }
})
