<template>
  <!--Logo start-->
  <div class="logo-main">
    <!--    <svg :class="`${color ? 'text-primary' : ''}`" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />-->
    <!--      <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />-->
    <!--      <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />-->
    <!--      <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />-->
    <!--    </svg>-->
    <b-img :src="path" :style="style" class="logo"></b-img>
  </div>
  <!--Logo End-->
</template>

<script>
import { BImg } from 'bootstrap-vue-3'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  components: { BImg },
  props: {
    color: { type: Boolean, default: false }
  },
  setup() {
    const store = useStore()
    const sidebarType = computed(() => store.getters['setting/sidebar_type'])
    const style = computed(() => {
      // 侧边栏缩进时，logo变小
      let height = '30px'
      if (sidebarType.value.includes('sidebar-mini')) height = '20px'
      return {
        height
      }
    })
    const path = require('@/assets/images/logo.png')
    return { path, style }
  }
}
</script>
