import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import md5 from 'js-md5'
import i18n from '@/i18n'

/**
 * 格式化数字
 * @param number 数字
 * @param decimals 保留小数
 * @param dec_point 小数点符号
 * @param thousands_sep 千分位符号
 * @param roundTag 舍入参数 默认ceil向上取,floor向下取,round四舍五入
 * @returns {string}
 */
export function number_format(number, decimals, dec_point, thousands_sep, roundTag) {
  number = (number + '').replace(/[^\d+-Ee.]/g, '')
  roundTag = roundTag || 'ceil'
  var n = !isFinite(+number) ? 0 : +number,
    price = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, price) {
      var k = Math.pow(10, price)
      return '' + parseFloat(Math[roundTag](parseFloat((n * k).toFixed(price * 2))).toFixed(price * 2)) / k
    }
  s = (price ? toFixedFix(n, price) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < price) {
    s[1] = s[1] || ''
    s[1] += new Array(price - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

/**
 * 创建随机字符串
 * @param len
 * @param type
 * @returns {string}
 */
export function randomString(len, type) {
  type = type || 'all'
  len = len || 32
  let $chars_en = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let $chars_num = '1234567890'
  let $chars
  if (type === 'all') {
    $chars = $chars_en + $chars_num
  } else if (type === 'num') {
    $chars = $chars_num
  } else {
    $chars = $chars_en
  }
  let maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

/**
 * 提取数字
 * @param value
 * @returns {string}
 */
export function extractInt(value) {
  return value.toString().replace(/\D/g, '')
}

/**
 * 是否为链接
 */
export function isURL(url) {
  let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~/])+$/
  return reg.test(url)
}

/**
 * 字符串是否为json
 * @param json
 * @returns {boolean}
 */
export function isJSON(json) {
  try {
    JSON.parse(json)
    return true
  } catch (e) {
    return false
  }
}

/**
 * 获取文件名称
 * @param fileStr
 * @returns {string}
 */
export function getFileName(fileStr) {
  if (typeof fileStr !== 'string') return ''
  return fileStr.replace(/(.*\/)*([^.]+).*/gi, '$2')
}

/**
 * 补零
 * @param num
 * @returns {string|*}
 */
export function zeroPadding(num) {
  return Number(num) < 10 ? '0' + num : num
}

/**
 * 日期补零
 * @param date
 * @returns {*}
 */
function dateZeroPadding(date) {
  return date
    .split('-')
    .map((item) => zeroPadding(item))
    .join('-')
}

/**
 * 时间补零
 * @param time
 * @returns {*}
 */
export function timeZeroPadding(time) {
  return time
    .split(':')
    .map((item) => zeroPadding(item))
    .join(':')
}

/**
 * 兼容创建date
 * @param str
 * @return {Date}
 */
export function createDate(str) {
  if (str instanceof Date) return str
  if (typeof str !== 'string') return new Date()
  let year = 0,
    month = 0,
    day = 0,
    hour = 0,
    min = 0,
    sec = 0
  const splitStr = str.split(' ')
  if (splitStr[0]) {
    const splitDate = splitStr[0].split(splitStr[0].indexOf('-') !== -1 ? '-' : '/')
    year = Number(splitDate[0])
    month = Number(splitDate[1]) - 1
    day = Number(splitDate[2])
  }
  if (splitStr[1]) {
    const splitTime = splitStr[1].split(':')
    hour = Number(splitTime[0])
    min = Number(splitTime[1])
    sec = Number(splitTime[2])
  }
  return new Date(year, month, day, hour, min, sec)
}

/**
 * 格式化时间
 * @param str
 * @constructor
 */
export function FormatDate(str) {
  const date = str ? createDate(str) : createDate()
  this.date = date
  const getDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
  const getTime = [date.getHours(), date.getMinutes(), date.getSeconds()].join(':')
  this.formatDate = dateZeroPadding(getDate)
  this.formatTime = timeZeroPadding(getTime)
  this.year = date.getFullYear()
  this.month = zeroPadding(date.getMonth() + 1)
  this.day = zeroPadding(date.getDate())
  this.hours = zeroPadding(date.getHours())
  this.minutes = zeroPadding(date.getMinutes())
  this.seconds = zeroPadding(date.getSeconds())
  this.$0 = {
    formatDate: getDate,
    formatTime: getTime,
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds()
  }
}

/**
 * 根据出生日期获取年龄
 * @param strAge
 * @returns {string|number}
 */
export function getAge(strAge) {
  if (typeof strAge !== 'string' && strAge.length === 0) return 'Unknown'
  let birArr = createDate(strAge)
  let birYear = Number(birArr.getFullYear())
  let birMonth = Number(birArr.getMonth() + 1)
  let birDay = Number(birArr.getDate())

  let date = createDate()
  let nowYear = date.getFullYear()
  let nowMonth = date.getMonth() + 1
  let nowDay = date.getDate()
  let returnAge

  let d = new Date(birYear, birMonth - 1, birDay)
  if (!(d.getFullYear() === birYear && d.getMonth() + 1 === birMonth && d.getDate() === birDay)) return 'Unknown'

  if (nowYear === birYear) return 0

  let ageDiff = nowYear - birYear
  if (ageDiff < 0) return 'Unknown'

  if (nowMonth === birMonth) {
    let dayDiff = nowDay - birDay
    returnAge = dayDiff < 0 ? ageDiff - 1 : ageDiff
  } else {
    let monthDiff = nowMonth - birMonth
    returnAge = monthDiff < 0 ? ageDiff - 1 : ageDiff
  }
  return returnAge
}

export function UTCString(value) {
  value = value.replace(/-/g, '/')
  var d = new Date(value + ' UTC')
  return d.toLocaleString('en-US')
}

export function UTCDate(value) {
  if (!value) return ''
  value = value.replace(/-/g, '/')
  var d = new Date(value + ' UTC')
  return d
}

/**
 * @转UTC时间
 * */
export function convertUTCDate(value) {
  if (typeof value === 'string') {
    value = createDate(value)
  }
  let year = value.getUTCFullYear()
  let month = value.getUTCMonth()
  let date = value.getUTCDate()
  let hours = value.getUTCHours()
  let minutes = value.getUTCMinutes()
  let seconds = value.getUTCSeconds()
  //   (year,month, date, hours, minutes, seconds)
  return new Date(`${year}-${month + 1}-${date}` + '  ' + `${hours}:${minutes}:${seconds}`)
}

// axios-baseurl
// export const SERVER_PATH = 'https://api.miopos.us'
// export const SERVER_PATH = 'https://sandbox-api.miopos.us'
// export const SERVER_PATH = 'http://192.168.101.202:23230'
export const SERVER_PATH = 'http://192.168.0.202:23230'


let server = axios.create({
  timeout: 5000
})
server.interceptors.request.use(function (config) {
  // 添加token
  config.headers['Authorization'] = store.getters['setting/user']?.token
  return config
})

export const RequestMethods = Object.freeze({
  POST: 1,
  GET: 2,
  DELETE: 3,
  HEAD: 4,
  OPTIONS: 5,
  PATCH: 6
})

function formatPath(url) {
  return isURL(url) ? url : SERVER_PATH + url
}

export function request(method) {
  // if (!server) {
  //   initServer()
  // }
  switch (method) {
    case RequestMethods.POST:
      return server.post
    case RequestMethods.GET:
      return server.get
    case RequestMethods.DELETE:
      return server.delete
    case RequestMethods.HEAD:
      return server.head
    case RequestMethods.OPTIONS:
      return server.options
    case RequestMethods.PATCH:
      return server.patch
    default:
      return server.post
  }
}

export function post(url, data, config) {
  return new Promise((resolve, reject) => {
    request(RequestMethods.POST)
      .call(this, formatPath(url), data, config)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        ElMessage({
          message: err?.response?.data || err?.message || err,
          type: 'error'
        })
        reject(err)
      })
  })
}

export function get(url, data) {
  return new Promise((resolve, reject) => {
    let fUrl = formatPath(url)
    if (fUrl.indexOf('?') === -1) {
      fUrl += '?'
      let parameters = []
      for (let i in data) {
        parameters.push(i + '=' + data[i])
      }
      fUrl += parameters.join('&')
    } else {
      let parameters = []
      for (let i in data) {
        parameters.push(i + '=' + data[i])
      }
      fUrl += '&' + parameters.join('&')
    }
    request(RequestMethods.GET)
      .call(this, fUrl)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        ElMessage({
          message: err.response.data || err.message,
          type: 'error'
        })
        reject(err)
      })
  })
}

export function upload() {
  const formData = new FormData()
  formData.append('bucketName', 'aikeeper')
  formData.append('files', arguments[0])
  return request(RequestMethods.POST).call(this, formatPath('/api/Common/upload'), formData)
}

export function uploads() {
  const formData = new FormData()
  formData.append('bucketName', 'aikeeper')
  for (let i = 0; i < arguments[0].length; i++) formData.append('files', arguments[0][i])
  return request(RequestMethods.POST).call(this, formatPath('/api/Common/upload'), formData)
}

// 上传失败处理方法
function uploadError(error, message) {
  ElMessage({
    message: message || i18n.global.t('uploadError'),
    type: 'error'
  })
  throw error
}
/**
 * arguments： 0 -- 文件 1 -- 错误消息
 * */
export async function asyncUpload() {
  const formData = new FormData()
  formData.append('bucketName', 'aikeeper')
  formData.append('files', arguments[0])
  let result = []
  let message = arguments[1]
  await request(RequestMethods.POST)
    .call(this, formatPath('/api/Common/upload'), formData)
    .then((res) => {
      if (res.status === 200) {
        result = res.data
      }
    })
    .catch((err) => {
      uploadError(err, message)
    })
  return result
}

export async function asyncUploads() {
  const formData = new FormData()
  formData.append('bucketName', 'aikeeper')
  for (let i = 0; i < arguments[0].length; i++) formData.append('files', arguments[0][i])
  let result = []
  let message = arguments[1]
  console.log('message', message)
  await request(RequestMethods.POST)
    .call(this, formatPath('/api/Common/upload'), formData)
    .then((res) => {
      if (res.status === 200) {
        result = res.data
      }
    })
    //
    .catch((err) => {
      uploadError(err, message)
    })
  return result
}

/**
 * 设置cookie
 * @param name
 * @param data
 * @param time
 */
export function setCookie(name, data, time) {
  document.cookie = name + '=' + data + ';max-age=' + time
}

/**
 * 获取cookie
 * @param name
 * @returns {string}
 */
export function getCookie(name) {
  const arrCookie = document.cookie.split('; ')
  let getData
  for (let i = 0; i < arrCookie.length; i++) {
    let itemArr = arrCookie[i].split('=')
    if (itemArr[0] === name) {
      getData = itemArr[1]
      break
    }
  }
  return getData
}

export function bindKey(value) {
  return md5(value)
}

export function getInitCookieData(merchantId, merchantTypes) {
  return JSON.stringify({
    id: merchantId,
    t: merchantTypes
  })
}

// 根据对象生成formData
export function generateFormData(target) {
  if (typeof target !== 'object' || Array.isArray(target)) return target
  let formData = new FormData()
  for (let key in target) {
    let value = target[key]
    // 默认数组会转成逗号相隔的字符串
    if (Array.isArray(target[key])) value = JSON.stringify(value)
    formData.append(key, value)
  }
  return formData
}

/**
 * @获取商户类型
 * 1--商户商家 2--第三方配送商家
 * */
export function getSellerType() {
  return store.getters['setting/user']?.seller?.types || ''
}

// 获取当前语言环境
export function getLocales() {
  return store.getters['setting/language']
}

// 检查是否具有权限
export function checkAuth(item) {
  console.log(item)
  let auth = 'admin1'
  if (auth === 'admin') return true
  else return item.includes(auth)
}

// 节流
export function throttle(fn, delay = 300) {
  let valid = true
  return function () {
    if (!valid) {
      return false
    }
    valid = false
    setTimeout(() => {
      fn.apply(this, arguments)
      valid = true
    }, delay)
  }
}

export const debounce = (fn, delay = 300, immediate = true) => {
  let timer = null // 借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    if (immediate) {
      let callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, delay)
      if (callNow) {
        fn.apply(this, arguments)
      }
    } else {
      timer = setTimeout(() => {
        fn.apply(this, arguments)
      }, delay)
    }
  }
}

/** @校验登录信息
 * vuex
 * seller-基础信息
 * token 令牌
 * refresh_token 刷新令牌
 * */
export function checkLogin() {
  const vuexUserInfo = store.getters['setting/user']
  const checkInfo = (item) => {
    return item && item.seller && item.token && item.refresh_token
  }
  return checkInfo(vuexUserInfo)
}

/** @表单校验不通过_跳转到第一个字段 */
export function jumpFirstErrorProp(dom, props) {
  let res = true
  for (let key in props) {
    if (res) {
      dom.scrollToField(key)
      res = false
    } else break
  }
}

/** @处理时间格式
 *  @param time String format： 09:00:00 / 21:00:00
 *  @返回AM/PM
 */
export function handleTime(time) {
  const hour = Number(time.split(':')[0])
  return hour - 12 > 0 ? [(hour - 12).toString().padStart(2, '0'), time.split(':')[1]].join(':') + ' PM' : time + ' AM'
}
