export const customerRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }

  return [
    {
      path: '/blacklist',
      name: formatName('blacklist'),
      meta: { auth: true, t: "menu.customer.child.blacklist.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: customerChildrenRoutes(formatName("blacklist"))
    }
  ]
}

const customerChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/blacklist/Blacklist')
    }
  ]
}
