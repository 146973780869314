export const orderRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }
  return [
    {
      path: '/order-history',
      name: formatName('order-history'),
      meta: { auth: true, t: "menu.orderMgmt.child.orderHistory.label", viewRouter: true, jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: orderChildrenRoutes(formatName("order-history"))
    }
  ]
}

const orderChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/order/OrderHistory.vue')
    }
  ]
}
