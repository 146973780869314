export default {
  setSetting: ({ commit }, payload) => commit('setSetting', payload),
  saveLocal: ({ commit }, payload) => commit('saveLocal', payload),
  app_name: ({ commit }, payload) => commit('app_name', payload),
  theme_scheme_direction: ({ commit }, payload) => commit('theme_scheme_direction', payload),
  theme_scheme: ({ commit }, payload) => commit('theme_scheme', payload),
  theme_color: ({ commit }, payload) => commit('theme_color', payload),
  header_navbar: ({ commit }, payload) => commit('header_navbar', payload),
  sidebar_color: ({ commit }, payload) => commit('sidebar_color', payload),
  sidebar_type: ({ commit }, payload) => commit('sidebar_type', payload),
  sidebar_menu_style: ({ commit }, payload) => commit('sidebar_menu_style', payload),
  language: ({ commit }, payload) => commit('language', payload),
  user: ({ commit }, payload) => commit('user', payload),
  open_status: ({ commit }, payload) => commit('open_status', payload),
  jurisdiction: ({ commit }, payload) => commit('jurisdiction', payload),
  is_admin: ({ commit }, payload) => commit('is_admin', payload),
  menu_auth_list: ({ commit }, payload) => commit('menu_auth_list', payload),
  function_auth_list: ({ commit }, payload) => commit('function_auth_list', payload),
  seller_id: ({ commit }, payload) => commit('seller_id', payload),
  bind_payment_status: ({ commit }, payload) => commit('bind_payment_status', payload)
}
