export const dishRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }

  return [
    {
      path: '/dishes',
      name: formatName('dishes'),
      meta: { auth: true, t: "menu.dishMgmt.child.dishes.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: dishChildrenRoutes(formatName("dishes"))
    },
    {
      path: '/combo',
      name: formatName('combo'),
      meta: { auth: true, t: "menu.dishMgmt.child.combo.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: comboChildrenRoutes(formatName("combo"))
    },
    {
      path: '/classification',
      name: formatName('classification'),
      meta: { auth: true, t: "menu.dishMgmt.child.dishClassification.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: classificationChildrenRoutes(formatName("classification"))
    },
    {
      path: '/cooking-method',
      name: formatName('cooking-method'),
      meta: { auth: true, t: "menu.dishMgmt.child.cookingMethod.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: cookingMethodChildrenRoutes(formatName('cooking-method'))
    },
    {
      path: '/flavor-of-food',
      name: formatName('flavor-of-food'),
      meta: { auth: true, t: "menu.dishMgmt.child.flavorOfFood.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: flavorOfFoodChildrenRoutes(formatName('flavor-of-food'))
    },
    {
      path: "/specification",
      name: formatName("specification"),
      meta: { auth: true, t: "menu.dishMgmt.child.specification.label", viewRouter: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: specificationChildrenRoutes(formatName('specification'))
    },
    {
      path: "/side-dish",
      name: formatName("side-dish"),
      meta: { auth: true, t: "menu.dishMgmt.child.sideDish.label", viewRouter: true, jurisdiction: [1] },
      component: () => import("../views/pages/manage/common/ViewRouter.vue"),
      children: sideDishChildrenRoutes(formatName("side-dish"))
    }
  ]
}

const dishChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/dishes/Dishes.vue')
    },
    {
      path: "/dishDetail",
      name: auth + ".dish-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.dishes.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/DishDetail")
    }
  ]
}

const comboChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/dishes/Combo.vue')
    },
    {
      path: "/comboDetail",
      name: auth + ".combo-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.combo.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/comboDetail")
    }
  ]
}

const classificationChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import('../views/pages/manage/dishes/DishClassification.vue')
    },
    {
      path: "/classification-detail",
      name: auth + ".classification-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.dishClassification.detail", jurisdiction: [1] },
      component: () => import('../components/pages/dishes/DishClassificationDetail.vue')
    }
  ]
}

const cookingMethodChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import("../views/pages/manage/dishes/CookingMethod.vue")
    },
    {
      path: "/cooking-method-detail",
      name: auth + ".cooking-method-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.cookingMethod.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/CookingMethodDetail.vue")
    }
  ]
}

const flavorOfFoodChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import("../views/pages/manage/dishes/FlavorOfFood.vue")
    },
    {
      path: "/flavor-of-food-detail",
      name: auth + ".flavor-of-food-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.flavorOfFood.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/FlavorOfFoodDetail.vue")
    }
  ]
}

const specificationChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import("../views/pages/manage/dishes/Specification.vue")
    },
    {
      path: "/specification-detail",
      name: auth + ".specification-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.specification.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/SpecificationDetail.vue")
    }
  ]
}

const sideDishChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1] },
      component: () => import("../views/pages/manage/dishes/SideDish.vue")
    },
    {
      path: "/side-dish-detail",
      name: auth + ".side-dish-detail",
      meta: { auth: true, t: "menu.dishMgmt.child.sideDish.detail", jurisdiction: [1] },
      component: () => import("../components/pages/dishes/SideDishDetail.vue")
    }
  ]
}
