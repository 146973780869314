// Getters
export default {
  app_name: (state) => state.setting.app_name,
  theme_scheme: (state) => state.setting.theme_scheme,
  theme_scheme_direction: (state) => state.setting.theme_scheme_direction,
  theme_style_appearance: (state) => state.setting.theme_style_appearance,
  theme_color: (state) => state.setting.theme_color,
  header_navbar: (state) => state.setting.header_navbar,
  sidebar_color: (state) => state.setting.sidebar_color,
  sidebar_type: (state) => state.setting.sidebar_type,
  sidebar_menu_style: (state) => state.setting.sidebar_menu_style,
  language: (state) => state.setting.language,
  user: (state) => state.setting.user,
  open_status: (state) => state.setting.open_status,
  jurisdiction: (state) => state.setting.jurisdiction,
  is_admin: (state) => state.setting.is_admin,
  menu_auth_list: (state) => state.setting.menu_auth_list,
  function_auth_list: (state) => state.setting.function_auth_list,
  seller_id: (state) => state.setting.seller_id,
  bind_payment_status: (state) => state.setting.bind_payment_status
}
