/**
 * 格式化数字
 * @param number 数字
 * @param decimals 保留小数
 * @param dec_point 小数点符号
 * @param thousands_sep 千分位符号
 * @param roundTag 舍入参数 默认ceil向上取,floor向下取,round四舍五入
 * @returns {string}
 */
function number_format(number, decimals = 0, dec_point = ".", thousands_sep = ",", roundTag = "ceil") {
  if (number < 0)
    number = (number + '').substring(1, number.toString().length).replace(/[^\d+-Ee.]/g, '');
  else
    number = (number + '').replace(/[^\d+-Ee.]/g, '');
  roundTag = roundTag || "ceil";
  var n = !isFinite(+number) ? 0 : +number,
    price = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, price) {
      var k = Math.pow(10, price);
      return '' + parseFloat(Math[roundTag](parseFloat((n * k).toFixed(price * 2))).toFixed(price * 2)) / k;
    };
  s = (price ? toFixedFix(n, price) : '' + Math.round(n)).split('.');
  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }

  if ((s[1] || '').length < price) {
    s[1] = s[1] || '';
    s[1] += new Array(price - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

export const formatMoney = (value, decimals, dec_point, thousands_sep, roundTag) => {
  return (Number(value) < 0 ? '-' : '') + "$ " + number_format(Number(value), decimals || 2, dec_point || ".",
    thousands_sep || ",", roundTag || "ceil");
}
