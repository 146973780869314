import DishClassification from './dishes/DishClassification.json'
import DishClassificationDetail from './dishes/DIshClassificationDetail.json'
import Dish from './dishes/Dish.json'
import DishDetail from './dishes/DishDetail.json'
import CookingMethod from './dishes/CookingMethod.json'
import CookingMethodDetail from './dishes/CookingMethodDetail.json'
import FlavorOfFood from './dishes/FlavorOfFood.json'
import FlavorOfFoodDetail from './dishes/FlavorOfFoodDetail.json'
import Specification from './dishes/Specification.json'
import SpecificationDetail from './dishes/SpecificationDetail.json'
import SideDish from './dishes/SideDish.json'
import SideDishDetail from './dishes/SideDishDetail.json'
import Combo from './dishes/Combo.json'
import ComboDetail from './dishes/ComboDetail.json'
import DishGroupDetail from './dishes/DishGroupDetail.json'
import AddDish from './dishes/AddDish.json'
import OrderHistory from './order/OrderHistory.json'
import StoreInformation from './setting/StoreInformation.json'
import OrderDetail from './order/OrderDetail.json'
import OrderStatus from './order/OrderStatus.json'
import OrderType from './order/OrderType.json'
import StaffList from './staff/StaffList.json'
import StaffDetail from './staff/StaffDetail.json'
import OpenStatus from './openStatus'
import StaffView from './staff/StaffView.json'
import OrderSetting from './setting/OrderSetting.json'
import ThirdPartyDeliveryTeam from './setting/ThirdPartyDeliveryTeam.json'
import Blacklist from './blacklist/Blacklist.json'
import MessageType from './message/MessageType.json'
import Permission from './setting/Permission.json'
import Comment from './member/Comment.json'
import Join from './join'
import Home from './home'
import Menu from './menu'
import Auth from './auth'
import BindPaymentPlatform from './bindPaymentPlatform/index.json'
import ChooseRole from './staff/ChooseRole.json'

export default {
  man: 'Male',
  woman: 'Female',
  unknown: 'Unknown',
  addMultilingual: 'Add multilingual',
  inStock: 'In stock',
  allInStock: 'All in stock',
  outOfStock: 'Out of stock',
  allOutOfStock: 'All out of stock',
  all: 'All',
  selectAll: 'Select all',
  add: 'Add',
  delete: 'Delete',
  deleteAll: 'Delete all',
  deleteSuccess: 'Delete succeeded',
  deleteFail: 'Delete failed',
  edit: 'Edit',
  see: 'See',
  ok: 'Ok',
  cancel: 'Cancel',
  confirm: 'Confirm',
  reset: 'Reset',
  reject: 'Reject',
  agree: 'Agree',
  next: 'Next',
  notNow: 'Not now',
  save: 'Save',
  saveAndContinue: 'Save and continue',
  import: 'Import',
  export: 'Export',
  operation: 'Operation',
  search: 'Search',
  selected: 'Selected',
  yes: 'Yes',
  no: 'No',
  tips: 'Tips',
  open: 'Open',
  close: 'Close',
  back: 'Back',
  noAddPrice: 'Do not add price',
  addPrice: 'Add price',
  serverConcatError: 'Network connection failed',
  noUsed: 'No used',
  min: 'min',
  operationSuccess: 'Operation succeeded',
  operationError: 'Operation failed',
  fileError: {
    0: 'Please upload a picture with a width of ',
    1: ' and a height of ',
    2: 'The image size must be controlled at '
  },
  welcome: 'Welcome to Merchant Management',
  noPermission: 'No permission',
  loginTimeout: 'Login timeout, please login again',
  unbindPayment: '',
  uploadError: 'Upload failed',
  uploadImgError: 'Failed to upload image',
  uploadThumbnailError: 'Failed to upload thumbnail',
  editSuccess: 'Successfully edit',
  editFail: 'Failed to edit',
  mile: 'mile',
  auth: Auth,
  menu: Menu,
  dishClassification: DishClassification,
  dishClsDetail: DishClassificationDetail,
  dish: Dish,
  dishDetail: DishDetail,
  cookingMethod: CookingMethod,
  cookingMethodDetail: CookingMethodDetail,
  flavorOfFood: FlavorOfFood,
  flavorOfFoodDetail: FlavorOfFoodDetail,
  specification: Specification,
  specificationDetail: SpecificationDetail,
  sideDish: SideDish,
  sideDishDetail: SideDishDetail,
  combo: Combo,
  comboDetail: ComboDetail,
  dishGroupDetail: DishGroupDetail,
  addDish: AddDish,
  orderHistory: OrderHistory,
  storeInformation: StoreInformation,
  orderDetail: OrderDetail,
  orderStatus: OrderStatus,
  orderType: OrderType,
  staffList: StaffList,
  staffDetail: StaffDetail,
  openStatus: OpenStatus,
  staffView: StaffView,
  orderSetting: OrderSetting,
  thirdPartyDeliveryTeam: ThirdPartyDeliveryTeam,
  home: Home,
  blacklist: Blacklist,
  messageType: MessageType,
  permission: Permission,
  comment: Comment,
  join: Join,
  bindPaymentPlatform: BindPaymentPlatform,
  chooseRole: ChooseRole
}
