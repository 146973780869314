export const memberRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }

  return [
    {
      path: '/comment',
      name: formatName('comment'),
      meta: { auth: true, t: "", jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/member/comment.vue')
    }
  ]
}
