export const staffRoutes = (parent, auth) => {
  function formatName(name) {
    return `${parent}.${auth}.${name}`
  }
  return [
    {
      path: '/staff-list',
      name: formatName('staff-list'),
      meta: { auth: true, t: "menu.staff.child.staffList.label", viewRouter: true, jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/common/ViewRouter.vue'),
      children: staffChildrenRoutes(formatName("staff-list"))
    }
  ]
}

const staffChildrenRoutes = (auth) => {
  return [
    {
      path: "",
      name: auth + ".main",
      meta: { auth: true, jurisdiction: [1, 2] },
      component: () => import('../views/pages/manage/staff/StaffList.vue')
    },
    {
      path: "/staff-detail",
      name: auth + ".staff-detail",
      meta: { auth: true, t: "menu.staff.child.staffList.detail", jurisdiction: [1, 2] },
      component: () => import("../components/pages/staff/StaffDetail")
    }
  ]
}
