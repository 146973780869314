// import {checkAuth} from "@/assets/common";
import store from '@/store'
function install(app) {
  app.directive('auth', {
    mounted(el, binding) {
      // admin直接放行
      if (store.getters['setting/is_admin']) return
      // 功能权限列表
      const functionList = store.getters['setting/function_auth_list']
      let key = binding.value
      if (!functionList.includes(key)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  })
}

export default {install}
